<template>
  <headBack>
    <template v-slot:title>
      <div class='title'>
        评价晒单
      </div>
    </template>
  </headBack>
  <div class='header_cont'></div>
  <div class="pj_main">
      <div class="rate">
          商品评价
        <van-rate v-model="rate"   :size="18" color="#00c291"/>
      </div>
    <div class="message">
      <van-field
          v-model="message"
          rows="2"
          autosize
          type="textarea"
          maxlength="120"
          placeholder="请输入评价"
          show-word-limit

      />
    </div>
    <div class="fileList">
      <van-uploader :after-read="afterReadImgs" upload-text='上传图片' v-model='fList' max-count='6' accept="image/*"/>
    </div>

  </div>
  <div>
    <van-button round type="success" @click="evaluateSave()">提交评价</van-button>
  </div>
</template>

<script>
import {setWxShareDate} from '@/libs/utils.js'
import { onMounted, ref, computed } from "vue"
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { Dialog } from 'vant'
import homeApi from '@axios/home'
import shopApi from '@axios/shop'
import {Toast} from "vant";
export default {
  name: "evaluateEdit",
  setup(){
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const rate = ref('0');
    const message = ref('');
    const fList = ref([]);
    const imgList = ref([])
    const evaluateSave =() => {
      if(rate.value == '0'){
        Toast("记得给个好评噢😯")
        return;
      }
      if(message.value == ''){
        Toast("请输入评价")
        return;
      }

      const params ={
        userId: store.state.uid,
        content: message.value,
        orderId:route.query.orderId,
        contentUrlList:imgList.value,
        star : rate.value,
        goodsId:''
      }
      shopApi.evaluateSave(params).then((res)=> {
           console.log(res)
           if(res.code == '0000'){
              router.push({
                name:'shopOrder'
              })
           }
      })
    }
    const afterReadImgs = (file) => {
         console.log(file)

      let formData = new FormData();
      formData.append('file',file.file)

      homeApi.imgUpload(formData).then((res)=>{
        // chufang.value[0] = {
        //   url : 'https://file.yihu365.cn' + res.message
        // };
        console.log(res)
        imgList.value.push(
            {
              picUrl:'https://file.yihu365.cn' + res.message
            }
        )
        console.log(imgList.value)
      })
    }
     return {
       rate,
       message,
       evaluateSave,
       afterReadImgs,
       fList
     }
  }
}
</script>

<style scoped lang="scss">

   .pj_main{
     box-sizing: border-box;
     padding:20px 30px;
     color:#323232;
     font-size: 28px;
     margin: 30px auto;
     width: 94%;
     background: #fafafa;
     border-radius: 16px;
   }
   ::v-deep(.van-uploader__upload){
     background: #fff;
     margin-top: 30px;
   }
   ::v-deep(.van-button){
     display: block;
      margin: 90px auto;
     width: 700px;
     background: #00c291;
   }
   .rate{
      display: flex;
      align-items: center;
      justify-content: space-between;
     margin-bottom: 30px;
   }
   .message{
     margin-bottom: 20px;
   }
   .fileList{
     ::v-deep(.van-uploader__upload){
       margin-top:0;
     }
   }
</style>
