import { render } from "./evaluateEdit.vue?vue&type=template&id=6f277eec&scoped=true"
import script from "./evaluateEdit.vue?vue&type=script&lang=js"
export * from "./evaluateEdit.vue?vue&type=script&lang=js"

import "./evaluateEdit.vue?vue&type=style&index=0&id=6f277eec&scoped=true&lang=scss"
script.render = render
script.__scopeId = "data-v-6f277eec"
/* hot reload */
if (module.hot) {
  script.__hmrId = "6f277eec"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('6f277eec', script)) {
    api.reload('6f277eec', script)
  }
  
  module.hot.accept("./evaluateEdit.vue?vue&type=template&id=6f277eec&scoped=true", () => {
    api.rerender('6f277eec', render)
  })

}

script.__file = "src/views/shop/detail/evaluate/evaluateEdit.vue"

export default script